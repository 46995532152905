<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <v-card-title class="pb-2">
        USUARIOS
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">

          <v-btn
            color="secondary"
            outlined
            class="mb-1"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-divider class="mt-2"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0 pl-0 pt-0">
        <v-col cols="12">
          <filter-by v-model="newItems" :filterFields="filterFields" :filterLists="filterLists"></filter-by>
        </v-col>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="newItems"
        :options.sync="options"
        :loading="loading"
      >
        <!-- name -->
        <template #[`item.fullname`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="30"
            >
              <v-img
                v-if="item.avatar"
                :src="require(`@/assets/images/avatars/${item.avatar}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.fullname) }}</span>
            </v-avatar>

            <router-link :to="{ name: 'user-view', params: { id: item.id } }" class="pl-3">
              {{ item.fullname }}
            </router-link>
          </div>
        </template>

        <!-- role -->
        <template #[`item.roles`]="{item}">
          <div
            class="d-flex align-center"
            v-for="(role, index) of item.roles"
            :key="role.role + String(index)"
          >
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(role.role)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(role.role)}--text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveUserRoleVariant(role.role)"
              >
                {{ resolveUserRoleIcon(role.role) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ roleOptions.find(rol => rol.value === role.role).text }}</span>
          </div>
        </template>

        <!-- status -->
        <template #[`item.stated_at`]="{item}">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.stated_at)"
            :class="`${resolveUserStatusVariant(item.stated_at)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.stated_at ? 'Activo' : 'Inactivo'}}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
          <!-- delete -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="setUser(item)"
                  color="info"
                >
                  <v-icon size="18">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Detalles</span>
            </v-tooltip>

            <!-- view  -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogDelete = true"
                  color="error"
                >
                  <v-icon size="18">
                    {{ icons.mdiCancel }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Inactivar</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <dialog-delete
        v-model="dialogDelete"
      >
      </dialog-delete>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiCancel,
  mdiExportVariant,
  mdiAccountOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import FilterBy from '@/components/widgets/filter/FilterBy.vue'
import DialogDelete from '@/components/widgets/dialog/DialogDelete.vue'
import useUsersList from './useUsersList'
import { useRouter } from '@core/utils'

export default {
  components: { FilterBy, DialogDelete },
  setup() {
    const {
      userListTable,
      tableColumns,
      roleOptions,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,

      fetchUsers,
      filterFields,
      filterLists,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
    } = useUsersList()

    watch(
      userListTable,
      val => {
        newItems.value = val
      },
      {
        deep: true,
      },
    )

    const newItems = ref([])
    const dialogDelete = ref(false)
    const { router } = useRouter()

    const setUser = async item => {
      router.push({ name: 'user-view', params: { id: item.id } })
    }

    return {
      avatarText,
      dialogDelete,
      fetchUsers,
      filterFields,
      filterLists,
      loading,
      newItems,
      options,
      resolveUserRoleIcon,
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      roleOptions,
      setUser,
      tableColumns,
      totalUserListTable,
      userListTable,
      userTotalLocal,

      // icons
      icons: {
        mdiAccountOutline,
        mdiCancel,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiPlus,
        mdiSquareEditOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.pointer {
  cursor: pointer;
}
</style>
