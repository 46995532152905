var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-2"},[_vm._v(" USUARIOS "),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-1",attrs:{"color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Exportar")])],1)],1)],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0 pl-0 pt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('filter-by',{attrs:{"filterFields":_vm.filterFields,"filterLists":_vm.filterLists},model:{value:(_vm.newItems),callback:function ($$v) {_vm.newItems=$$v},expression:"newItems"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.newItems,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"30"}},[(item.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.fullname)))])],1),_c('router-link',{staticClass:"pl-3",attrs:{"to":{ name: 'user-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.fullname)+" ")])],1)]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return _vm._l((item.roles),function(role,index){return _c('div',{key:role.role + String(index),staticClass:"d-flex align-center"},[_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveUserRoleVariant(role.role)) + "--text me-3"),attrs:{"size":"30","color":_vm.resolveUserRoleVariant(role.role)}},[_c('v-icon',{attrs:{"size":"18","color":_vm.resolveUserRoleVariant(role.role)}},[_vm._v(" "+_vm._s(_vm.resolveUserRoleIcon(role.role))+" ")])],1),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.roleOptions.find(function (rol) { return rol.value === role.role; }).text))])],1)})}},{key:"item.stated_at",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.stated_at)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.stated_at)}},[_vm._v(" "+_vm._s(item.stated_at ? 'Activo' : 'Inactivo')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"info"},on:{"click":function($event){return _vm.setUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Detalles")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){_vm.dialogDelete = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCancel)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Inactivar")])])],1)]}}],null,true)}),_c('dialog-delete',{model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }