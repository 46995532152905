import store from '@/store'
import {
  mdiAccountCheckOutline,
  mdiAccountEditOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiAccountGroupOutline,
  mdiAccountCogOutline,
  mdiDnsOutline,
  mdiAccountCashOutline,
} from '@mdi/js'
import { ref, watch, computed } from '@vue/composition-api'

export default function useUsersList() {

  const userListTable = ref([])

  const tableColumns = [
    { text: 'USUARIO', value: 'fullname' },
    { text: 'EMAIL', value: 'email' },
    { text: 'ROL', value: 'roles', sortable: false, cellClass: 'py-2' },
    { text: 'ESTADO', value: 'stated_at', sortable: false },
    {
      text: 'ACCIONES',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const filterLists = ref([])
  filterLists.value =  [
    {
      value: 'stated_at',
      list: [{text: 'Activo', value: true}, {text: 'Inactivo', value: false}]
    },
    {
      value: 'roles',
      list: [
        {text: 'Administrador', value: 'admin'},
        {text: 'Coordinador', value: 'project_manager'},
        {text: 'Formulador', value: 'resource_manager'},
        {text: 'Comité Evaluador', value: 'commitee'},
        {text: 'Auditor', value: 'auditor'},
        {text: 'Patrocinador', value: 'sponsor'},
        {text: 'Miembro', value: 'team_member'},
      ]
    }
  ]


  const filterFields = [
    { text: 'USUARIO', value: 'fullname', type: 'text' },
    { text: 'EMAIL', value: 'email', type: 'text'},
    { text: 'ROL', value: 'roles', type: 'lookup' },
    { text: 'ESTADO', value: 'stated_at', type: 'lookup' },
  ]

  const roleOptions = [
    { text: 'Administrador', value: 'admin' },
    { text: 'Coordinador', value: 'project_manager' },
    { text: 'Formulador', value: 'resource_manager' },
    { text: 'Comité Evaluador', value: 'commitee' },
    { text: 'Auditor', value: 'auditor' },
    { text: 'Patrocinador', value: 'sponsor' },
    { text: 'Miembro', value: 'team_member' },
  ]

  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [false],
  })
  const userTotalLocal = ref([])

  // fetch data
  const fetchUsers = () => {
    loading.value = true
    store
      .dispatch('users/fetchUsers')
      .then(response => {
        const data = ref([])
        if (response) data.value =  response.map(ele => {
          return JSON.parse(ele['listusers']).users
        })

        userListTable.value = data.value[0]

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        loading.value = false
      })
  }

  fetchUsers()

  const selectListMails = computed(() => {
    return userListTable.value.map(user => {
      return user.email
    })
  })

  watch([], () => {
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'team_member') return 'secondary'
    if (role === 'commitee') return 'warning'
    if (role === 'project_manager') return 'info'
    if (role === 'resource_manager') return 'error'
    if (role === 'sponsor') return 'success'
    if (role === 'admin') return 'primary'
    if (role === 'auditor') return 'accent'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'team_member') return mdiAccountOutline
    if (role === 'commitee') return  mdiAccountGroupOutline
    if (role === 'project_manager') return mdiAccountCogOutline
    if (role === 'resource_manager') return  mdiAccountEditOutline
    if (role === 'sponsor') return mdiAccountCashOutline
    if (role === 'admin') return mdiDnsOutline
    if (role === 'auditor') return mdiAccountCheckOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status) return 'success'
    else return 'error'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    filterFields,
    filterLists,
    userListTable,
    tableColumns,
    totalUserListTable,
    loading,
    options,
    userTotalLocal,
    fetchUsers,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
    roleOptions,
    selectListMails
  }
}
