<template>
  <v-dialog
    :value="value"
    max-width="450"
    persistent
  >
    <v-card class="pa-3">
      <v-card-text class="headline ">
        ¿Está seguro que desea {{deleteMsg ? 'eliminar' : 'inactivar'}} el registro?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="$emit('input', false)"
        >
          Retornar
        </v-btn>
        <v-btn
          color="success"
          :loading="loading"
          @click="$emit('delete')"
        >
          {{deleteMsg ? 'Eliminar' : 'Inactivar'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
export default {
  name: 'DialogDelete',
  props: {
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    deleteMsg: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
