<template>
  <v-row class="d-flex align-center flex-wrap mb-1">
    <v-col md="3" cols="12" sm="3" class="pl-2 pr-1">
      <v-select
        class="pt-0 mt-0 input-filter"
        outlined
        dense
        :items="filterFields.filter(item => item !== null)"
        v-model="filterField"
        hide-details
        color="rgba(211, 36, 43, 0.4)"
        label="Filtrar por">
      </v-select>
    </v-col>
    <v-col
      cols="12"
      md="2"
      sm="2"
      class="px-1"
    >
      <v-select
        class="pt-0 mt-0 input-filter"
        outlined
        dense
        :items="filterOperators"
        v-model="filterOperator"
        hide-details
        color="rgba(211, 36, 43, 0.4)"
        label="Operador">
      </v-select>
    </v-col>
    <v-col cols="12" md="2" sm="2" class="px-1" v-show="filterOperator && filterType !== 'lookup'">
      <v-text-field
        class="pt-0 mt-0 input-filter"
        name="filterTerm"
        @input="storeTerm"
        hide-details
        outlined
        dense
        :label="filterTermLabel"
        :mask="filterTermMask"
        :rules='filterTermRules'
        return-masked-value
        color="rgba(211, 36, 43, 0.4)"
        v-model="filterTerm"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="2" sm="2" class="px-1" v-show="filterOperator === 'between'">
      <v-text-field
        class="pt-0 mt-0 input-filter"
        name="filterTerm2"
        @input="storeTerm2"
        hide-details
        outlined
        dense
        :label="filterTermLabel"
        :mask="filterTermMask"
        :rules='filterTermRules'
        return-masked-value
        color="rgba(211, 36, 43, 0.4)"
        v-model="filterTerm2"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="2" sm="2" class="px-1" v-show="filterType === 'lookup'">
      <v-autocomplete
        class="pt-0 mt-0 input-filter"
        outlined
        dense
        :items="filterLookupItems"
        :label="filterLookupLabel"
        v-model="filterLookupValue"
        hide-details
        color="rgba(211, 36, 43, 0.4)"
      ></v-autocomplete>
    </v-col>
    <v-col
      cols="12"
      md="1"
      sm="1"
      class="px-1"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            x-small
            dark
            v-on="on"
            color="primary"
            @click="onClearAllFilters"
          >
            <v-icon color="">{{ icons.mdiNotificationClearAll }}</v-icon>
          </v-btn>
        </template>
        <span>Limpiar</span>
      </v-tooltip>
    </v-col>
    <v-divider vertical></v-divider>
  </v-row>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { mdiNotificationClearAll } from '@mdi/js'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
    filterFields: {
      type: Array,
      default: () => [],
      required: true,
    },
    filterLists: {
      type: Array,
      default: () => {
        return []
      },
      required: false,
    },
  },
  data() {
    return {
      i: 0,
      icons: {
        mdiNotificationClearAll,
      },
      band: false,
      filterDefs: {
        text: {
          contains: { display: 'Contiene', function: this.filterByTextContains },
          startsWith: { display: 'Comienza con', function: this.filterByTextStartsWith },
        },
        number: {
          equal: { display: 'Igual', function: this.filterByNumberEqual, decimalPoint: 1 },
          greater: { display: 'Mayor que', function: this.filterByNumberGreater, decimalPoint: 1 },
          less: { display: 'Menor que', function: this.filterByNumberLess, decimalPoint: 1 },
          between: { display: 'Entre', function: this.filterByNumberBetween, decimalPoint: 1 },
        },
        date: {
          equal: { display: 'Igual', function: this.filterByDateEqual, format: 'DD/MM/YYYY' },
          greater: { display: 'Mayor que', function: this.filterByDateGreater, format: 'DD/MM/YYYY' },
          less: { display: 'Menor que', function: this.filterByDateLess, format: 'DD/MM/YYYY' },
          between: { display: 'Entre', function: this.filterByDateBetween, format: 'DD/MM/YYYY' },
        },
        lookup: {
          is: { display: 'Es', function: this.filterByLookupIs },
          isNot: { display: 'No es', function: this.filterByLookupIsNot },
        },
      },
      items: [],
      filterField: '',
      filterType: '',
      filterOperators: [],
      filterOperator: '',
      filterTerm: '',
      filterTerm2: '',
      filterTermMask: '',
      filterTermLabel: '',
      filterTermRules: [],
      dateFilterFormat: 'DD/MM/YYYY',
      filterLookupItems: [],
      filterLookupValue: '',
      filterLookupLabel: '',
    }
    /*  ejemplo
    filterFields: [
                {text: 'First Name', value: 'firstName', type: 'text'},
                {text: 'Last Name', value: 'lastName', type: 'text'},
                {text: 'DOB', value: 'dob', type: 'date'},
                {text: 'GPA', value: 'gpa', type: 'number'},
                {text: 'Address', value: 'address', type: 'text'},
                {text: 'City', value: 'city', type: 'text'},
                {text: 'County', value: 'county', type: 'text'},
                {text: 'Zip', value: 'zip', type: 'number'},
                {text: 'State', value: 'state', type: 'lookup'}
            ], */
  },
  created() {
    if (this.filterFields.some(ele => ele.value === this.filterField2) && this.route === this.$route.name) {
      this.filterField = this.filterField2
    } else this.$store.commit('filter/removeState')
  },
  mounted() {
    this.items = this.value
  },
  computed: {
    ...mapState({
      filterField2: state => state.filter.filterField,
      filterOperator2: state => state.filter.filterOperator,
      filterTermLocal: state => state.filter.filterTerm,
      filterTermLocal2: state => state.filter.filterTerm2,
      route: state => state.filter.route,
    }),
    data() {
      if (
        this.filterField &&
        this.filterOperator &&
        (this.filterTerm || this.filterLookupValue || typeof this.filterLookupValue === 'boolean')
      ) {
        const filterFunction = this.filterDefs[this.filterType][this.filterOperator]['function']
        if (this.filterType === 'number') {
          const decimalPoint = this.filterDefs[this.filterType][this.filterOperator]['decimalPoint'] || 0
          if (this.filterOperator === 'between') {
            if (this.filterTerm && this.filterTerm2) {
              return filterFunction(this.items, this.filterField, this.filterTerm, this.filterTerm2, decimalPoint)
            } else return this.items
          } else if (this.filterOperator !== 'between') {
            return filterFunction(this.items, this.filterField, this.filterTerm, decimalPoint)
          } else {
            return this.items
          }
        } else if (this.filterType === 'date') {
          const format = this.filterDefs[this.filterType][this.filterOperator]['format'] || this.dateFilterFormat
          if (
            this.filterOperator === 'between' &&
            this.rulesIsValidDate(this.filterTerm) &&
            this.rulesIsValidDate(this.filterTerm2)
          ) {
            return filterFunction(this.items, this.filterField, this.filterTerm, this.filterTerm2, format)
          } else if (this.filterOperator !== 'between' && this.rulesIsValidDate(this.filterTerm)) {
            return filterFunction(this.items, this.filterField, this.filterTerm, format)
          } else {
            return this.items
          }
        } else if (this.filterType === 'lookup') {
          return filterFunction(this.items, this.filterField, this.filterLookupValue)
        } else {
          return filterFunction(this.items, this.filterField, this.filterTerm)
        }
      } else {
        return this.items
      }
    },
  },
  watch: {
    filterField(newValue) {
      if (newValue !== '') {
        this.$store.commit('filter/setFilterField', newValue)
        this.$store.commit('filter/resetTerms')
        this.$store.commit('filter/setRoute', this.$route.name)
      }
      if (newValue === 'balance') {
        this.$emit('balance', true)
      } else {
        this.$emit('balance', false)
      }
      const filterType = this.filterFields.find(item => item.value === newValue).type
      if (filterType) {
        this.filterType = filterType
        this.filterOperators = this.getFilterOperators(this.filterDefs[filterType])
        this.filterOperator = ''
        this.clearFilterTerms()
        if (this.filterOperator2) this.filterOperator = this.filterOperator2
      } else {
        this.filterType = ''
        this.filterOperators = []
        this.filterOperator = ''
        this.clearFilterTerms()
      }
    },
    filterOperator(newValue) {
      if (newValue) this.$store.commit('filter/setFilterOperator', newValue)
      this.clearFilterTerms()
      if (this.filterTermLocal) this.filterTerm = this.filterTermLocal
      if (this.filterTermLocal2) this.filterTerm2 = this.filterTermLocal2
      if (this.filterType === 'text') {
        this.filterTermMask = ''
        this.filterTermLabel = 'Filtro'
      } else if (this.filterType === 'number') {
        if (this.filterField === 'gpa') {
          this.filterTermMask = '#.#'
          this.filterTermLabel = '#.#'
        } else if (this.filterField === 'zip') {
          this.filterTermMask = '#####'
          this.filterTermLabel = '#####'
        } else {
          this.filterTermMask = ''
          this.filterTermLabel = 'Valor'
        }
      } else if (this.filterType === 'date') {
        this.filterTermMask = '##/##/####'
        this.filterTermLabel = 'DD/MM/YYYY'
        this.filterTermRules = [this.rulesIsValidDate]
      } else if (this.filterType === 'lookup') {
        let lookupItems = []
        let item = this.filterLists.find(item => item.value === this.filterField)
        lookupItems = item.list
        this.filterLookupLabel = this.filterFields.find(item => item.value === this.filterField).text
        this.filterLookupItems = lookupItems
        this.filterLookupValue = ''
      }
    },
    data(val) {
      this.band = true
      this.$emit('input', val)
      this.$nextTick(() => {
        this.band = false
      })
    },
    value(val) {
      if (!this.band) {
        this.items = val
      }
    },
  },
  methods: {
    storeTerm(val) {
      if (val) this.$store.commit('filter/setFilterTerm', val)
    },
    storeTerm2(val) {
      if (val) this.$store.commit('filter/setFilterTerm2', val)
    },
    getFilterOperators(filterDef) {
      let oprs = []
      if (filterDef) {
        for (let key in filterDef) {
          oprs.push({ text: filterDef[key]['display'], value: key })
        }
      }
      return oprs
    },
    clearFilterTerms() {
      this.filterTerm = ''
      this.filterTerm2 = ''
      this.filterTermMask = ''
      this.filterTermLabel = 'Filtro'
      this.filterTermRules = []
      this.filterLookupValue = ''
      this.filterLookupItems = []
      this.filterLookupLabel = ''
    },
    rulesIsValidDate(value) {
      return moment(value, this.dateFilterFormat, true).isValid()
    },
    onClearAllFilters() {
      this.clearFilterTerms()
      this.$store.commit('filter/removeState')
      this.filterField = this.filterFields[0].value
    },
    filterByTextContains(list, fieldName, fieldValue) {
      let mod = fieldValue.replace(/\s+/g, ` `).replace(/ /g, `(.+)`)
      const re = new RegExp(mod, 'i')
      return this.filterByRegExp(list, fieldName, fieldValue, re)
    },
    filterByTextStartsWith(list, fieldName, fieldValue) {
      let mod = fieldValue.replace(/\s+/g, ` `).replace(/ /g, `(.+)`)
      const re = new RegExp('^' + mod, 'i')
      return this.filterByRegExp(list, fieldName, fieldValue, re)
    },
    filterByRegExp(list, fieldName, fieldValue, regExp) {
      return list.filter(item => {
        if (item[fieldName] !== undefined) {
          return regExp.test(item[fieldName])
        } else {
          return true
        }
      })
    },
    filterByNumberEqual(list, fieldName, fieldValue, decimalPoint) {
      decimalPoint = decimalPoint || 0
      return list.filter(item => {
        if (item[fieldName] !== undefined) {
          return Number(item[fieldName]).toFixed(decimalPoint) === Number(fieldValue).toFixed(decimalPoint)
        } else {
          return true
        }
      })
    },
    filterByNumberGreater(list, fieldName, fieldValue, decimalPoint) {
      decimalPoint = decimalPoint || 0
      return list.filter(item => {
        if (item[fieldName] !== undefined) {
          return Number(fieldValue) < Number(item[fieldName])
        } else {
          return true
        }
      })
    },
    filterByNumberLess(list, fieldName, fieldValue, decimalPoint) {
      decimalPoint = decimalPoint || 0
      return list.filter(item => {
        if (item[fieldName] !== undefined) {
          return Number(item[fieldName]) < Number(fieldValue)
        } else {
          return true
        }
      })
    },
    filterByNumberBetween(list, fieldName, fieldValue1, fieldValue2, decimalPoint) {
      decimalPoint = decimalPoint || 0
      return list.filter(item => {
        if (item[fieldName] !== undefined) {
          return Number(item[fieldName]) >= Number(fieldValue1) && Number(item[fieldName]) <= Number(fieldValue2)
        } else {
          return true
        }
      })
    },
    filterByDateEqual(list, fieldName, fieldValue, format) {
      format = format || this.dateFilterFormat
      return list.filter(item => {
        if (item[fieldName] !== undefined) {
          return moment(item[fieldName]).isSame(moment(fieldValue, format), 'day')
        } else {
          return true
        }
      })
    },
    filterByDateGreater(list, fieldName, fieldValue, format) {
      format = format || this.dateFilterFormat
      return list.filter(item => {
        if (item[fieldName] !== undefined) {
          return moment(item[fieldName]).isAfter(moment(fieldValue, format), 'day')
        } else {
          return true
        }
      })
    },
    filterByDateLess(list, fieldName, fieldValue, format) {
      format = format || this.dateFilterFormat
      return list.filter(item => {
        if (item[fieldName] !== undefined) {
          return moment(item[fieldName]).isBefore(moment(fieldValue, format), 'day')
        } else {
          return true
        }
      })
    },
    filterByDateBetween(list, fieldName, fieldValue1, fieldValue2, format) {
      format = format || this.dateFilterFormat
      return list.filter(item => {
        if (item[fieldName] !== undefined) {
          return moment(item[fieldName]).isBetween(
            moment(fieldValue1, format),
            moment(fieldValue2, format),
            'day',
            '[]',
          )
        } else {
          return true
        }
      })
    },
    filterByLookupIs(list, fieldName, fieldValue) {
      return list.filter(item => {
        if (Array.isArray(item[fieldName])) {
          let finded = item[fieldName].find(ele => {
            for (const key in ele) {
              if (ele[key] === fieldValue) return true
            }
          })
          if (finded) return true
          else return false
        }
        if (item[fieldName] !== undefined) {
          return item[fieldName] === fieldValue
        } else {
          return true
        }
      })
    },
    filterByLookupIsNot(list, fieldName, fieldValue) {
      return list.filter(item => {
        if (Array.isArray(item[fieldName])) {
          let finded = item[fieldName].find(ele => {
            for (const key in ele) {
              if (ele[key] === fieldValue) return true
            }
          })
          if (finded) return false
          else return true
        }
        if (item[fieldName] !== undefined) {
          return item[fieldName] !== fieldValue
        } else {
          return true
        }
      })
    },
  },
}
</script>
